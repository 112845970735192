/**
 * @category Search Form
 * @packageDocumentation
 */
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchFormContext, SearchFormProvider } from 'components/contexts/SearchFormContext';
import CustomDatepicker from 'components/datePicker/customDatepicker/CustomDatepicker';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import { OccupancyPickerMobile } from 'components/searchForm/OccupancyPickerMobile';
import { OccupancyPickerMobileHome } from 'components/searchForm/OccupancyPickerMobileHome';
import { SuggestionFieldMobile } from 'components/searchForm/SuggestionFieldMobile';
import { SuggestionFieldMobileHome } from 'components/searchForm/SuggestionFieldMobileHome';
import { isHomeRoute } from 'utils/uriUtils';

interface SearchFormOffscreenProps {
  offscreenMode: OffscreenMode;
  offscreenHeader?: HTMLDivElement;
}

const SearchFormOffscreen: React.FC<SearchFormOffscreenProps> = ({ offscreenMode, offscreenHeader }) => {
  const { pathname } = useLocation();
  const { submitDatesForm } = useContext(SearchFormContext);
  const homeRoute = isHomeRoute(pathname);

  switch (offscreenMode) {
    case OffscreenMode.Occupancy:
      return homeRoute ? (
        <OccupancyPickerMobileHome />
      ) : (
        <SearchFormProvider>
          <OccupancyPickerMobile />
        </SearchFormProvider>
      );
    case OffscreenMode.Datepicker:
      return homeRoute ? (
        <CustomDatepicker offscreenMode={offscreenMode} />
      ) : (
        <SearchFormProvider>
          <CustomDatepicker submit={submitDatesForm} offscreenMode={offscreenMode} />;
        </SearchFormProvider>
      );
    case OffscreenMode.Destination:
      return homeRoute ? (
        <SuggestionFieldMobileHome offscreenMode={offscreenMode} offscreenHeader={offscreenHeader} />
      ) : (
        <SearchFormProvider>
          <SuggestionFieldMobile offscreenMode={offscreenMode} offscreenHeader={offscreenHeader} />
        </SearchFormProvider>
      );
    default:
      return <React.Fragment />;
  }
};

export default SearchFormOffscreen;
