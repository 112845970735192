import React, { useCallback, useContext, useEffect, useState } from 'react';
import { OffscreenContext } from 'TopContexts';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import OccupancyPicker from 'components/searchForm/OccupancyPicker';

interface OccupancyPickerMobileHomeProps {}

export const OccupancyPickerMobileHome: React.FC<OccupancyPickerMobileHomeProps> = () => {
  const { occupancy: occupancyExternal, setOccupancy: setOccupancyExternal } = useContext(SearchFormContext);
  const { hideOffscreen } = useContext(OffscreenContext);
  const [occupancy, setOccupancy] = useState(occupancyExternal);

  useEffect(() => {
    setOccupancy(occupancyExternal);
  }, [occupancyExternal]);

  const submit = useCallback(() => {
    setOccupancyExternal(occupancy);
    hideOffscreen();
  }, [hideOffscreen, occupancy, setOccupancyExternal]);

  return (
    <OccupancyPicker occupancy={occupancy} setOccupancy={setOccupancy} submit={submit}>
      {() => <div />}
    </OccupancyPicker>
  );
};
