/**
 * @category Search Form
 * @packageDocumentation
 */
import React, { FormEvent, useContext } from 'react';
import { Trans } from 'react-i18next';
import { OffscreenContext } from 'TopContexts';
import { LayoutContext } from 'components/contexts/LayoutContext';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import CustomDatepicker from 'components/datePicker/customDatepicker/CustomDatepicker';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import Styled from 'components/searchForm/SearchForm.mobile.home.styled';
import StyledSearchForm from 'components/searchForm/SearchForm.styled';
import SuggestionField from 'components/searchForm/SuggestionField/SuggestionField';
import StyledCommon from 'style/Common.styled';
import { sumGuests } from 'utils/occupancyUtils';

const SearchFormHomeMobile: React.FC = () => {
  const { isMobileLayout } = useContext(LayoutContext);
  const { setOffscreenMode } = useContext(OffscreenContext);

  const { occupancy, onSuggestion, onAutoSuggestion, onSearchTermChange, submitForm } = useContext(SearchFormContext);

  const searchButton = (
    <StyledCommon.PrimaryButton data-testid="search-button" type="button" tabIndex={4} onClick={submitForm}>
      <Trans i18nKey="search-bar.search">Search</Trans>
    </StyledCommon.PrimaryButton>
  );

  return (
    <Styled.SearchForm>
      <form onSubmit={(e: FormEvent) => e.preventDefault()}>
        <Styled.SuggestionPicker
          data-testid="suggestion-picker"
          onClick={() => setOffscreenMode(OffscreenMode.Destination)}
        >
          <SuggestionField
            onEnter={submitForm}
            onSearchTerm={onSearchTermChange}
            onSelectPlace={onSuggestion}
            onAutoPlace={onAutoSuggestion}
            readonly={isMobileLayout}
          />
        </Styled.SuggestionPicker>
        <CustomDatepicker>
          {(checkinDate, checkoutDate) => (
            <Styled.DatepickerWidget>
              <span>{checkinDate.long}</span>
              <span>{checkoutDate.long}</span>
            </Styled.DatepickerWidget>
          )}
        </CustomDatepicker>
        <div className="uk-position-relative uk-height-1-1">
          <div className="uk-height-1-1">
            <StyledSearchForm.RoomPicker
              tabIndex={3}
              onClick={() => setOffscreenMode(OffscreenMode.Occupancy)}
              onFocus={() => setOffscreenMode(OffscreenMode.Occupancy)}
              className="uk-flex uk-flex-middle uk-flex-left uk-width-expand uk-height-1-1"
            >
              <span>
                <Trans
                  i18nKey="offscreen.occupancy-desc"
                  defaults="{ rooms, plural, =1 {# Room} other {# Rooms} } {guests, plural, =1 {# Guest} other {# Guests}}"
                  values={{
                    rooms: occupancy.length,
                    guests: sumGuests(occupancy),
                  }}
                />
              </span>
            </StyledSearchForm.RoomPicker>
          </div>
        </div>
        {searchButton}
      </form>
    </Styled.SearchForm>
  );
};

export default SearchFormHomeMobile;
