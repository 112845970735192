import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { SEARCH_OCCUPANCY } from 'backend/localStorageKeys';
import { SearchStateContext } from 'components/contexts/SearchStateContext';
import useDestination from 'components/searchForm/useDestination';
import useSearchFormCallback from 'components/searchForm/useSearchFormCallback';
import useSearchFormCheckinCheckout from 'components/searchForm/useSearchFormCheckinCheckout';
import useSearchFormParameters from 'components/searchForm/useSearchFormParameters';
import { env } from 'environments/environment';
import { AmplitudeLogging } from 'utils/logging/AmplitudeLogging';
import { defaultOccupancy, occupancyAreEqual } from 'utils/occupancyUtils';
import { isDestinationRoute, isHomeRoute, isHotelOrDestinationRoute, isHotelRoute } from 'utils/uriUtils';
import useLocalStorage from 'utils/useLocalStorage';

export const SearchStateProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  const formParameters = useSearchFormParameters();

  const [placeId, setPlaceId] = useState(formParameters?.placeId);
  const [bounds, setBounds] = useState<string | undefined>(formParameters?.bounds);

  const { checkin, checkout, setCheckinCheckout } = useSearchFormCheckinCheckout(
    formParameters?.checkin,
    formParameters?.checkout,
  );

  const [occupancy, setOccupancy] = useLocalStorage<RoomOccupancy[]>(
    SEARCH_OCCUPANCY,
    formParameters?.occupancy || defaultOccupancy(env.searchBar),
  );

  const [destination, setDestination] = useDestination(placeId, { checkin, checkout, occupancy });

  const { onSearch, updateSearchQuery } = useSearchFormCallback(placeId);

  // formParameters need deep compare
  useDeepCompareEffect(() => {
    if (formParameters?.occupancy !== undefined) {
      setOccupancy(formParameters.occupancy);
    }
    if (formParameters?.placeId !== undefined) {
      setPlaceId(formParameters.placeId);
    }
  }, [formParameters, setOccupancy]);

  useEffect(() => {
    if (
      (!formParameters?.checkin || !formParameters.checkout || !formParameters.occupancy) &&
      formParameters?.placeId
    ) {
      if (isHotelOrDestinationRoute(pathname) && formParameters) {
        updateSearchQuery(checkin, checkout, occupancy, formParameters);
      }
    }
  }, [checkin, checkout, formParameters, pathname, occupancy, updateSearchQuery]);

  const submit = useCallback(
    (
      place: Place | undefined,
      _bounds: string | undefined,
      _checkin: string,
      _checkout: string,
      _occupancy: RoomOccupancy[],
    ) => {
      const hotelPage = isHotelRoute(pathname);
      const destinationPage = isDestinationRoute(pathname);

      if (
        !(hotelPage || destinationPage) ||
        checkin !== _checkin ||
        checkout !== _checkout ||
        placeId !== place?.id ||
        bounds !== _bounds ||
        !occupancyAreEqual(occupancy, _occupancy)
      ) {
        if (isHomeRoute(pathname)) {
          AmplitudeLogging.pushSearchInitiatedEvent({
            checkin: _checkin,
            checkout: _checkout,
            occupancy: _occupancy,
            destination: place,
          });
        }

        setPlaceId(place?.id);
        setDestination(place);
        setBounds(_bounds);
        setCheckinCheckout({ checkin: _checkin, checkout: _checkout });
        setOccupancy(_occupancy);
        onSearch(place, _bounds, _checkin, _checkout, _occupancy);
      }
    },
    [
      pathname,
      checkin,
      checkout,
      placeId,
      occupancy,
      setDestination,
      setCheckinCheckout,
      setOccupancy,
      onSearch,
      bounds,
    ],
  );

  const submitDestination = useCallback(
    (_destination: Place) => {
      submit(_destination, undefined, checkin, checkout, occupancy);
    },
    [checkin, checkout, occupancy, submit],
  );

  const submitBounds = useCallback(
    (_bounds: string) => {
      submit(destination, _bounds, checkin, checkout, occupancy);
    },
    [destination, checkin, checkout, occupancy, submit],
  );

  return (
    <SearchStateContext.Provider
      value={{
        submit,
        submitDestination,
        submitBounds,
        occupancy,
        checkin,
        checkout,
        destination,
        bounds,
        setPlaceId,
      }}
    >
      {children}
    </SearchStateContext.Provider>
  );
};
