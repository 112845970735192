import React, { useContext } from 'react';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import SuggestionField from 'components/searchForm/SuggestionField/SuggestionField';

interface SuggestionFieldMobileProps {
  offscreenMode?: OffscreenMode;
  offscreenHeader?: HTMLDivElement;
}

export const SuggestionFieldMobile = ({ offscreenMode, offscreenHeader }: SuggestionFieldMobileProps) => {
  const { onAutoSuggestion, submitForm, submitDestinationForm } = useContext(SearchFormContext);

  return (
    <SuggestionField
      onEnter={submitForm}
      onSearchTerm={() => undefined}
      onSelectPlace={submitDestinationForm}
      onAutoPlace={onAutoSuggestion}
      offscreenMode={offscreenMode}
      offscreenHeader={offscreenHeader}
    />
  );
};
