import React, { useCallback, useContext } from 'react';
import { OffscreenContext } from 'TopContexts';
import { Place } from 'backend/api/place/placeModel';
import { SearchFormContext } from 'components/contexts/SearchFormContext';
import OffscreenMode from 'components/offscreen/OffscreenMode';
import SuggestionField from 'components/searchForm/SuggestionField/SuggestionField';

interface SuggestionFieldMobileHomeProps {
  offscreenMode?: OffscreenMode;
  offscreenHeader?: HTMLDivElement;
}

export const SuggestionFieldMobileHome = ({ offscreenMode, offscreenHeader }: SuggestionFieldMobileHomeProps) => {
  const { onSuggestion } = useContext(SearchFormContext);
  const { hideOffscreen } = useContext(OffscreenContext);

  const onSuggestionHide = useCallback(
    (place: Place) => {
      onSuggestion(place);
      hideOffscreen();
    },
    [hideOffscreen, onSuggestion],
  );

  return (
    <SuggestionField
      onEnter={hideOffscreen}
      onSearchTerm={() => undefined}
      onSelectPlace={onSuggestionHide}
      onAutoPlace={() => undefined}
      offscreenMode={offscreenMode}
      offscreenHeader={offscreenHeader}
    />
  );
};
