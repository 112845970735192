import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'TopContexts';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { HotjarEvent, hotjarEvent } from 'backend/hotjarTags';
import { ToastType } from 'components/Toast.styled';
import { SearchStateContext } from 'components/contexts/SearchStateContext';
import { env } from 'environments/environment';
import { ClientError, ClientErrorCode } from 'errors/clientError';
import { processError } from 'errors/errorUtils';
import { getPeriodOverMaxNightsMessage, isPeriodOverMaxNights, parseDate } from 'utils/dateUtils';

interface Context {
  submitForm: () => void;
  submitDestinationForm: (place: Place) => void;
  submitDatesForm: (checkin: string, checkout: string) => void;
  onSearchTermChange: (s: string) => void;
  onSuggestion: (d: Place | undefined) => void;
  onAutoSuggestion: (d: Place | undefined) => void;
  destinationTerm: string;
  place: Place | undefined;
  setDates: (s: string, e: string) => void;
  checkin: string;
  checkout: string;
  occupancy: RoomOccupancy[];
  setOccupancy: React.Dispatch<React.SetStateAction<RoomOccupancy[]>>;
}

export const SearchFormContext = React.createContext<Context>({
  submitForm: () => undefined,
  submitDestinationForm: () => undefined,
  submitDatesForm: () => undefined,
  occupancy: [],
  checkin: '',
  checkout: '',
  place: undefined,
  destinationTerm: '',
  onSearchTermChange: () => undefined,
  onSuggestion: () => undefined,
  onAutoSuggestion: () => undefined,
  setOccupancy: () => undefined,
  setDates: () => undefined,
});

export const SearchFormProvider: React.FC = ({ children }) => {
  const [t] = useTranslation();
  const {
    submit,
    occupancy: occupancyExternal,
    checkin: checkinExternal,
    checkout: checkoutExternal,
    destination: destinationExternal,
  } = useContext(SearchStateContext);
  const { setToast } = useContext(ToastContext);

  const [place, setPlace] = useState(destinationExternal);
  const [autoPlace, setAutoPlace] = useState<Place | undefined>(undefined);

  const [destinationTerm, setDestinationTerm] = useState(place?.name || '');

  const [occupancy, setOccupancy] = useState(occupancyExternal);
  const [checkin, setCheckin] = useState(checkinExternal);
  const [checkout, setCheckout] = useState(checkoutExternal);
  const setDates = useCallback((_checkin: string, _checkout: string) => {
    setCheckin(_checkin);
    setCheckout(_checkout);
  }, []);

  useEffect(() => {
    setPlace(destinationExternal);
    setAutoPlace(undefined);
  }, [destinationExternal]);

  useEffect(() => {
    setOccupancy(occupancyExternal);
  }, [occupancyExternal]);

  useEffect(() => {
    setCheckin(checkinExternal);
  }, [checkinExternal]);

  useEffect(() => {
    setCheckout(checkoutExternal);
  }, [checkoutExternal]);

  useEffect(() => {
    if (place?.name) {
      setDestinationTerm(place.name);
    }
  }, [place]);

  const setAutoPlaceAndClear = useCallback((_place: Place | undefined) => {
    setAutoPlace(_place);
    if (_place) {
      setPlace(undefined);
    }
  }, []);

  const submitFormInternal = useCallback(
    (_checkin: string, _checkout: string, _place: Place | undefined) => {
      if (!_place) {
        const term = destinationTerm.trim();

        if (term.length === 0) {
          setToast(t('index.search.not-found', 'Please enter destination!'), ToastType.error);
        } else if (term.length < env.searchBar.minimalTermLength) {
          setToast(t('search-bar.destination-too-short', 'Please type more characters'), ToastType.error);
        } else {
          const message = t(
            'errors.hotels.destination-missing',
            'Destination not supported. We keep adding new destinations every day!',
          );
          const error = {
            clientCodes: [ClientErrorCode.UnsupportedDestination],
            action: () => setToast(message, ToastType.error),
          };

          processError(new ClientError(ClientErrorCode.UnsupportedDestination, [message]), {
            known: [error],
            default: error,
          });
        }

        return;
      }

      if (isPeriodOverMaxNights(parseDate(_checkin), parseDate(_checkout))) {
        setToast(getPeriodOverMaxNightsMessage(t), ToastType.error);

        return;
      }
      hotjarEvent(HotjarEvent.SearchClicked);

      submit(_place, undefined, _checkin, _checkout, occupancy);
    },
    [occupancy, submit, destinationTerm, setToast, t],
  );

  const submitDatesForm = useCallback(
    (_checkin: string, _checkout: string) => submitFormInternal(_checkin, _checkout, place || autoPlace),
    [autoPlace, place, submitFormInternal],
  );

  const submitDestinationForm = useCallback(
    (_place: Place) => submitFormInternal(checkin, checkout, _place),
    [checkin, checkout, submitFormInternal],
  );

  const submitForm = useCallback(
    () => submitFormInternal(checkin, checkout, place || autoPlace),
    [autoPlace, checkin, checkout, place, submitFormInternal],
  );

  return (
    <SearchFormContext.Provider
      value={{
        occupancy,
        setOccupancy,
        submitForm,
        submitDestinationForm,
        submitDatesForm,
        checkin,
        checkout,
        setDates,
        place,
        destinationTerm,
        onSuggestion: setPlace,
        onAutoSuggestion: setAutoPlaceAndClear,
        onSearchTermChange: setDestinationTerm,
      }}
    >
      {children}
    </SearchFormContext.Provider>
  );
};
