export enum PlaceType {
  hotel = 'hotel',
  city = 'city',
  airport = 'airport',
  area = 'area',
  region = 'region',
  country = 'country',
}

export interface Place {
  id: string;
  type: PlaceType;
  name: string;
  label: string;
  latitude?: number;
  longitude?: number;
}
