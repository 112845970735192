import React from 'react';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';

interface Context {
  submit: (
    place: Place,
    bound: string | undefined,
    checkin: string,
    checkout: string,
    occupancy: RoomOccupancy[],
  ) => void;
  submitDestination: (place: Place) => void;
  submitBounds: (bounds: string) => void;
  occupancy: RoomOccupancy[];
  checkin: string;
  checkout: string;
  destination: Place | undefined;
  bounds: string | undefined;
  setPlaceId: (placeId: string) => void;
}

export const SearchStateContext = React.createContext<Context>({
  submit: () => undefined,
  submitDestination: () => undefined,
  submitBounds: () => undefined,
  occupancy: [],
  checkin: '',
  checkout: '',
  destination: undefined,
  bounds: undefined,
  setPlaceId: () => undefined,
});
