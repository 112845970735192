import { HotelDealExtended, HotelRoom } from 'backend/api/hotel/dealModel';
import { Hotel, HotelContact, HotelExtended, HotelInfo } from 'backend/api/hotel/hotelModel';
import { CurrencyCode } from 'backend/api/model';
import { ExtrasDetails } from 'backend/api/trip/tripRequest';
import { EnumIterator } from 'backend/enumIterator';

/* eslint-disable no-magic-numbers */
export enum TripStatus {
  Draft = 0,
  InAttempt = 1,
  InProcess = 2,
  Confirmed = 3,
  Cancelled = 4,
  Failed = 6,
  CancelledError = 9,
  RequiredSuspicious = 10,
  Blocked = 12,
  InProcessUnresolved = 14,
  CancelledPaid = 20,
  Fraud = 21,
  BookedViaHotel = 22,
  RequiredNationality = 23,
  Required = 24,
  PendingRecovery = 25,
  DuplicatedBooking = 26,
  PendingRefund = 27,
}

export interface TripPreferences {
  rebook?: boolean;
  resale?: boolean;
}

export interface Trip {
  id: number;
  userId?: number;
  encryptedTripId: string;
  status: TripStatus;
  specialRequests?: string;
  guest: GuestDetails;
  preferences: TripPreferences;
  hotel: TripHotel;
  rooms: RoomOccupancy[];
  deal: HotelDealExtended;
  hotelRooms: HotelRoom[];
  extras?: ExtrasDetails;
}

export interface TripHotel extends Hotel {
  contact?: HotelContact;
  info?: HotelInfo;
}

export interface TripId {
  id: number;
  encryptedTripId: string;
  status: TripStatus;
}

export interface CreatedTripId extends TripId {
  redirectUrl?: string;
}

export interface Checkout {
  id: string;
  hotel: HotelExtended;
  rooms: RoomOccupancy[];
  deal: HotelDealExtended;
  hotelRooms: HotelRoom[];
}

export interface RoomOccupancy {
  adults: number;
  children?: number[];
}

export interface GuestDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface CancellationFee {
  currencyCode: CurrencyCode;
  fee: number;
}

export enum PaymentRequestStatus {
  Draft = 1,
  Completed = 2,
  Failed = 3,
}

export enum PaymentRequestReason {
  DateChanged = 1,
  AdditionalBed = 2,
  AdditionalGuest = 3,
  RoomTypeChanged = 4,
  BoardTypeChanged = 5,
  HotelChanged = 6,
  Other = 7,
}

export interface PaymentRequest {
  id: number;
  status: PaymentRequestStatus;
  currencyCode: CurrencyCode;
  amount?: number;
  amountUsd?: number;
  reason?: PaymentRequestReason;
  comments?: string;
}

export enum CancellationReason {
  ChangedPlans = 3,
  WebSite = 12,
  MistakeOrBookedTwice = 13,
}

export interface Insurance {
  quoteId: string;
  insurancePolicyId: number;
  policyStartDate: string;
  policyEndDate: string;
  status: string;
  price: number;
  disclaimer: string;
  currency: CurrencyCode;
  policyTermsUrl: string;
  policyType: string;
  policyName: string;
  policyCode: string;
  totalTax: number;
  totalAmountWithoutTax: number;
  totalTaxFormatted: string;
  totalAmountWithoutTaxFormatted: string;
}

export enum InsuranceSubmitStatus {
  included = 1,
  excluded = 2,
  NYorHI = 3,
}

export interface InsurancePolicy {
  status: InsuranceSubmitStatus;
  insurancePolicyId: number | undefined;
  amount: number | undefined;
}

export const CancellationReasonIterator = new EnumIterator(CancellationReason);
