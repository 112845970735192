import { LoadScript } from '@react-google-maps/api';
import qs from 'qs';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppRoutes from 'AppRoutes';
import Styled from 'AppTop.styled';
import { DebugContext, MetaContext, PageTypeContext, SettingsContext, SignInDialogContext } from 'TopContexts';
import { useSession } from 'atoms/hooks/useSession';
import { useUserLocation } from 'atoms/hooks/useUserLocation';
import { hotjarUser } from 'backend/hotjarTags';
import { DEBUG_MODE_KEY } from 'backend/localStorageKeys';
import CookiePanel from 'components/CookiePanel';
import { OAuthProvider } from 'components/contexts/OAuthContext';
import { SearchFormProvider } from 'components/contexts/SearchFormContext';
import { SearchStateProvider } from 'components/contexts/SearchStateProvider';
import AppPromotionScreen from 'components/offscreen/app/AppPromotionScreen';
import { SignInDialog } from 'components/signin/SignInDialog';
import useRedirectCounter from 'components/useRedirectCounter';
import BodyProvider from 'contexts/BodyProvider';
import OffscreenProvider from 'contexts/OffscreenProvider';
import { env } from 'environments/environment';
import useOnRouteScrollingRestore from 'useOnRouteScrollingRestore';
import { LogInfoMemoryStorage } from 'utils/storage/memory/LogInfoMemoryStorage';
import useLocalStorage from 'utils/useLocalStorage';
import { useClearRokt } from 'utils/useRokt';

const P_LANGUAGE = 'language';

const loadingElement = <div />;

const AppTop = () => {
  const [isDebugMode, setDebugMode] = useLocalStorage(DEBUG_MODE_KEY, false);
  const { setLanguageCode } = useContext(SettingsContext);
  const { signInDialogMode } = useContext(SignInDialogContext);
  const { isGoogleCrawler } = useContext(MetaContext);
  const location = useLocation();
  const { session } = useSession();

  const [pageType, setPageType] = useState('homePage');
  const { data } = useUserLocation();

  useEffect(() => {
    const userId = session.principal?.hasUser()?.id;

    LogInfoMemoryStorage.userId = userId;

    if (session.initialized) {
      hotjarUser(userId ? userId.toString(10) : null);
    }
  }, [session.principal, session.initialized]);

  useEffect(() => {
    LogInfoMemoryStorage.clientLocation = data;
  }, [data]);

  // todo: remove this part in a separate task
  useEffect(() => {
    const parameters = qs.parse(location.search, { ignoreQueryPrefix: true });
    const lang = parameters[P_LANGUAGE] as string;

    if (lang) {
      setLanguageCode(lang, true);
    }
  }, [location.search, setLanguageCode]);

  useClearRokt();
  useOnRouteScrollingRestore();
  useRedirectCounter();

  if (!isGoogleCrawler && !session.initialized) {
    return null;
  }

  return (
    <DebugContext.Provider value={{ isDebugMode, setDebugMode }}>
      <PageTypeContext.Provider value={{ pageType, setPageType }}>
        <SearchStateProvider>
          <SearchFormProvider>
            <LoadScript
              googleMapsApiKey={env.googleMap.apiKey}
              libraries={env.googleMap.libraries}
              loadingElement={loadingElement}
            >
              <OAuthProvider>
                <OffscreenProvider>
                  {env.mobileApp.showPromotionScreen && <AppPromotionScreen />}
                  <BodyProvider>
                    <Styled.Root>
                      <CookiePanel />

                      {signInDialogMode !== undefined && <SignInDialog />}
                      <AppRoutes />
                    </Styled.Root>
                  </BodyProvider>
                </OffscreenProvider>
              </OAuthProvider>
            </LoadScript>
          </SearchFormProvider>
        </SearchStateProvider>
      </PageTypeContext.Provider>
    </DebugContext.Provider>
  );
};

export default AppTop;
