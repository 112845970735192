/* eslint-disable no-magic-numbers */
/**
 * @category Configuration
 * @packageDocumentation
 */
import { PriceRoundingMethod, RateDisplayModel } from 'backend/api/general/generalModel';
import { CurrencyCode } from 'backend/api/model';
import { Configuration } from 'environments/configuration';
import currencies from 'environments/defaultCurrencies';
import supportLanguages from 'environments/supportLanguages';

/**
 * Development configuration, used for  https://client.holistoqa.com.
 */
const conf: Configuration = {
  layouts: {
    desktopWidth: 1280,
    smallSmartphoneWidth: 390,
    superSmallSmartphoneWidth: 370,
  },
  googleTagManager: {
    id: 'GTM-5ZMZ7VF',
    turnOnNewRelic: true,
  },
  hotJar: {
    id: 2992079,
    snippetVersion: 6,
  },
  facebookPixel: {
    id: 880749375941500,
  },
  riskified: {
    shopName: 'www.holisto.com',
  },

  social: {
    trustpilot: 'https://www.trustpilot.com/evaluate/splittytravel.com',
  },

  inputs: {
    specialRequestMaxLength: 1000,
    phoneMinLength: 6,
    phoneMaxLength: 14,
    basicMaxLength: 100,
    basicMinLength: 2,
  },

  searchBar: {
    searchDateRange: 450,
    maxNights: 30,
    maxAdults: 4,
    maxChildren: 4,
    maxOccupancy: 4,
    defaultOccupancyCount: 1,
    checkinShiftDays: 7,
    defaultAdultsCount: 1,
    defaultFirstAdultsCount: 2,
    maxChildAge: 17,
    minChildAge: 0,
    defaultChildAge: 12,
    maxSuggestions: 20,
    minimalTermLength: 2,
    maximumTermLength: 150,
    queriesToKeepCount: 3,
    lengthOfStay: 2,
  },

  googleMap: {
    scale: 17,
    apiKey: 'AIzaSyD5hD7Td6Kxt_bGo2ej5WP-91v1lTEFIIo',
    libraries: ['places'],
    hotelListZoom: 10,
    oneHotelZoomPC: 15,
    maxHotelsToDisplay: 50,
    minHotelsToDisplay: 10,
  },

  hotelList: {
    itemsPerPage: 20,
    visibleAmenitiesCount: 2,
    maxSpecialProposalsPerHotel: 3,
    maxSpecialProposalsPerHotelMobile: 3,
  },

  hotel: {
    minRoomsToDisplay: 2,
    maxTopAmenitiesToDisplayHighlight: 6,
    maxTopAmenitiesToDisplay: 12,
    maxTopAmenitiesToDisplayMobile: 6,
    maxRowsHotelDetails: 4,
    maxReviewsToDisplay: 3,
    maxRowsReviewTitle: 2,
    maxRowsReviewContent: 5,
    maxRowsReviewTitleMobile: 1,
    maxRowsReviewContentMobile: 4,
  },

  myBookings: {
    salePartialRefundAfterCheckoutHours: 72,
    saleTripBeforeCheckinHours: 48,
    saleMaxPercentOfOriginal: 0.7,
    saleMinPercentOfOriginal: 0.2,
    saleDefaultPercentOfMax: 0.5,
    saleDefaultFlexiblePricePercentage: 15,
  },

  amplitude: {
    apiKey: '0ce46002188ef9aff3f2c82c5eef9b61',
  },

  developers: {
    noRedirectOnError: false,
    showTemporaryElements: false,
    logging: true,
  },
  name: 'production',
  api: {
    endpoint: 'https://client.holisto-services.com',
    urlConfiguration: {
      AUTHORIZE_TRIP_PAYMENT: '/2.0/trips/{encryptedTripId}/authorize',
      CANCEL_TRIP: '/2.0/trips/{encryptedTripId}',
      CREATE_TRACK_HOTEL_OFFER: '/2.0/hotels/offers/track',
      CREATE_TRIP: '/2.0/trips',
      CREATE_TRIP_CHARGE: '/2.0/trips/{encryptedTripId}/charges',
      EMAIL_TRIP_INFO: '/2.0/trips/{encryptedTripId}/emails',
      ENCRYPT_TRIP_ID: '/2.0/trips/{tripId}/encrypt',
      FIND_CAMPAIGN: '/2.0/campaigns',
      FIND_CLIENT_LOCATION: '/2.0/client/location',
      FIND_HOTELS: '/2.0/hotels',
      FIND_PLACES: '/2.0/places',
      FIND_TRANSLATIONS: '/2.0/translations',
      GET_CHECKOUT: '/2.0/checkouts/{checkoutId}',
      GET_DICTIONARY: '/2.0/dictionary',
      GET_HOTEL_DEALS: '/2.0/hotels/{hotelId}/deals',
      GET_HOTEL_OFFERS: '/2.0/hotels/offers',
      GET_HOTEL_INFO: '/2.0/hotels/{hotelId}/info',
      GET_PLACE: '/2.0/places/{placeId}',
      GET_SERVICE_MAP: '/2.0/services',
      GET_STATUS: '/2.0/status',
      GET_TRIP: '/2.0/trips/{encryptedTripId}',
      GET_TRIP_CANCELLATION_FEE: '/2.0/trips/{encryptedTripId}/cancellation-fee',
      GET_TRIP_PAYMENT_REQUEST: '/2.0/trips/{encryptedTripId}/payment-requests/{paymentRequestId}',
      GET_USER_DETAILS: '/2.0/users/{userId}',
      GET_USER_TRIPS: '/2.0/users/{userId}/trips',
      LOGIN_OAUTH_USER: '/2.0/users/login/oauth',
      LOGIN_USER: '/2.0/users/login',
      LOGOUT_USER: '/2.0/users/{userId}/logout',
      RECOVER_USER_PASSWORD: '/2.0/users/password',
      SIGNUP_OAUTH_USER: '/2.0/users/signup/oauth',
      SIGNUP_USER: '/2.0/users/signup',
      UPDATE_USER_DETAILS: '/2.0/users/{userId}',
      UPDATE_USER_PASSWORD: '/2.0/users/{userId}/password',
      VERIFY_USER: '/2.0/users/{userId}/verify',
      CREATE_SECONDARY_TRIP: '/2.0/secondary/trips',
      UPDATE_SECONDARY_TRIP: '/2.0/secondary/trips/{encryptedTripId}',
      DELETE_SECONDARY_TRIP: '/2.0/secondary/trips/{encryptedTripId}',
      GET_SECONDARY_TRIP: '/2.0/secondary/trips/{encryptedTripId}',
      GET_AB_TESTS: '/1.0/abtesting',
      GET_TRIP_INSURANCE_QUOTE: '/2.0/checkouts/{checkoutId}/insurance',
    },
  },
  campaign: {
    fallbackName: 'Holisto_Organic_US',
    fallbackRateDisplayModel: RateDisplayModel.Net,
    fallbackPriceRoundingMethod: PriceRoundingMethod.Round,
  },
  location: {
    fallbackCountryCode: 'US',
  },
  currencies,
  times: {
    showProcessingDialogFrom: 750,
    suggestionsDebounce: 500,
    initiateHotelSearchTimeout: 10000,
    priceOutdatedAlert: 30 * 60 * 1000,
    expiredOfferAlertHotel: 30 * 60 * 1000,
    expiredOfferAlertCheckout: 60 * 60,
    expiredOfferCountdown: 30 * 60,
    listUpdateInterval: 5000,
    currencyRatesMaxAge: 60000,
    campaignMaxAge: 14 * 24 * 3600 * 1000,
    i18nrequestTimeout: 3000,
    filterByNameDebounce: 300,
    filtersWithCheckboxDebounce: 500,
    recentBookingsCheckInMinutes: 120,
    urlCookieMaxAge: 365 * 24 * 3600 * 1000,
    tokenMaxAge: 7 * 24 * 3600 * 1000,
    userIdMaxAge: 7 * 24 * 3600 * 1000,
    locationMaxAge: 3600 * 1000,
    offsetTimezoneHours: 12,
    recentSearchMaxAge: 12 * 3600 * 1000,
    mapBoundsDebounce: 100,
    abTestsCookieMaxAge: 60 * 60 * 1000,
  },
  i18n: {
    packs: ['holisto-web'],
    debug: false,
    loadNotConfirmed: true,
    fallbackLanguage: 'EN',
    languages: supportLanguages,
  },
  paypal: {
    apiKey: 'AeEAKX7ta9OokJq5gGahVBfGhq-piZBXuPm7cv5i7WpDnFI6DJVQA7AY0_7kKHc5HQKgdVBk5_63D3DZ',
    envType: 'sandbox',
  },
  oauth: {
    googleAppId: '1009007708919-3qjsa50ikas69tde95nb1kb2imagf58o.apps.googleusercontent.com',
    facebookAppId: '863765658862670',
    appleClientId: 'com.holisto.web',
  },
  avuxi: {
    projectId: '6176716da904e838e65eb9c4',
    mapId: '62336600a904e838e65ec036',
  },
  mobileApp: {
    googlePlayLink: 'https://holisto.onelink.me/y6RH/android',
    appStoreLink: 'https://holisto.onelink.me/y6RH/iOS',
    promoAppLink: 'https://holisto.onelink.me/y6RH/promo',
    menuAppLink: 'https://holisto.onelink.me/y6RH/menu',
    showPromotionScreen: false,
    appsFlyer: {
      appId: 'fc45407e-5a04-4206-b2e8-acdf45fd6d62',
      confirmationBannerId: '3e86ec92-78ca-4a15-9563-7ae910ebdd3f',
    },
  },
  canonicalLinks: {
    hotelPage: '/hotels/united-states-of-america_ca-_san-francisco/133991_hotel-adagio-autograph-collection',
    destinationsPage: '/destinations/united-states-of-america_nevada_las-vegas?placeId=R2008',
  },
  disableLoader: {
    googleCrawler: true,
    users: true,
  },
  metaSearch: {
    organicCampaignPattern: /^Holisto_Organic_/i,
    enabled: true,
  },
  currencyExchange: {
    endpoint: 'https://forex.holisto.com',
    currency: CurrencyCode.USD,
  },
};

export default conf;
