import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Place } from 'backend/api/place/placeModel';
import { RoomOccupancy } from 'backend/api/trip/tripModel';
import { getDataProvider } from 'backend/dataProvider';
import { SEARCH_DESTINATION } from 'backend/localStorageKeys';
import { processError } from 'errors/errorUtils';
import usePlaceErrors from 'errors/usePlaceErrors';
import { createHotelSearchUri, isHotelRoute } from 'utils/uriUtils';

type BackDestinationUrlParams = {
  occupancy: RoomOccupancy[];
  checkin: string;
  checkout: string;
};

const useDestination = (initialPlaceId: string | undefined, backDestinationUrlParams: BackDestinationUrlParams) => {
  const location = useLocation();
  const [placeId, setPlaceId] = useState<string | undefined>(initialPlaceId);
  const [storedValue, setStoredValue] = useState<Place | undefined>(() => {
    try {
      const item = window.localStorage.getItem(SEARCH_DESTINATION);

      return item ? (JSON.parse(item) as Place) : undefined;
    } catch (e) {
      return undefined;
    }
  });
  const { checkin, checkout, occupancy } = backDestinationUrlParams;
  const [backDestinationsUrl] = useState(() =>
    createHotelSearchUri(storedValue?.label, storedValue?.id, undefined, checkin, checkout, occupancy),
  );

  const clearPlaceId = useCallback(() => setPlaceId(undefined), []);

  const placeErrors = usePlaceErrors(backDestinationsUrl, clearPlaceId);

  const loading = useRef(false);
  const isHotelPage = useMemo(() => isHotelRoute(location.pathname), [location.pathname]);

  const setValue = useCallback(
    (value: Place | undefined) => {
      setPlaceId(value?.id);
      setStoredValue(value);
      window.localStorage.setItem(SEARCH_DESTINATION, JSON.stringify(value));
    },
    [setStoredValue],
  );

  useEffect(() => {
    setPlaceId(initialPlaceId);
  }, [initialPlaceId]);

  useEffect(() => {
    if (!loading.current && placeId && storedValue?.id !== placeId && (!isHotelPage || backDestinationsUrl)) {
      loading.current = true;
      setStoredValue(undefined);
      getDataProvider()
        .then((dataProvider) => dataProvider.getDestination(placeId))
        .then((place) => setValue(place))
        .catch((reason) => processError(reason, placeErrors))
        .finally(() => {
          loading.current = false;
        });
    }
  }, [backDestinationsUrl, isHotelPage, loading, placeErrors, placeId, setValue, storedValue]);

  const ret: [Place | undefined, (value: Place | undefined) => void] = [storedValue, setValue];

  return ret;
};

export default useDestination;
