/**
 * @category Utils
 * @packageDocumentation
 */
import { PriceRoundingMethod, RateDisplayModel } from 'backend/api/general/generalModel';
import { HotelDeal } from 'backend/api/hotel/dealModel';

/**
 * Mapping of escaped html codes to their original string representation
 * @param str
 */
const escapedToHTML = (str: string) => {
  switch (str) {
    case '&amp;':
      return '&';
    case '&quot;':
      return '"';
    case '&lt;':
      return '<';
    case '&gt;':
      return '>';
    default:
      return '';
  }
};

export const getRoundFunction = (roundMethod: PriceRoundingMethod) => {
  switch (roundMethod) {
    case PriceRoundingMethod.Ceiling:
      return Math.ceil;
    case PriceRoundingMethod.Floor:
      return Math.floor;
    case PriceRoundingMethod.Round:
    default:
      return Math.round;
  }
};

/**
 * Simple de-escaping method for displaying the (temporary?) raw html obtained from server
 * @param str
 */
export const unescapeHTML = (str: string | null | undefined) => {
  if (!str) {
    return '';
  }

  return str.replace(/(&quot;|&lt;|&gt;|&amp;)/g, (_: string, item: string) => escapedToHTML(item));
};

/**
 * Customizable function to print the price with a currency
 * @param amount - amount to print
 * @param currencyCode - currency code for the locale
 * @param languageCode - language code for the locale
 * @param roundingFunction - a required rounding function (preventing the situation with large amount of digits after .)
 */
export function printMoney(
  amount: number | undefined,
  currencyCode: string,
  languageCode: string,
  roundingFunction?: (x: number) => number,
): string {
  let am: number | string = amount || 0;
  const decimals: Intl.NumberFormatOptions = {};

  if (roundingFunction) {
    decimals.maximumFractionDigits = 0;
    decimals.minimumFractionDigits = 0;

    am = roundingFunction(am);
  }

  return new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: currencyCode,
    ...decimals,
  }).format(am);
}

export function printWholeMoney(
  amount: number | undefined,
  currencyCode: string,
  languageCode: string,
  roundMethod: PriceRoundingMethod,
): string {
  return printMoney(amount, currencyCode, languageCode, getRoundFunction(roundMethod));
}

export function getTotalPrice(rateDisplayModel: RateDisplayModel, deal: HotelDeal | undefined): number | undefined {
  if (!deal) {
    return undefined;
  }

  switch (rateDisplayModel) {
    case RateDisplayModel.AllInclusive:
      return deal.totalPriceWithHotelFees;
    case RateDisplayModel.RoomAndFees:
      return deal.totalRoomsPriceWithHotelFees;
    case RateDisplayModel.Gross:
      return deal.totalPrice;
    case RateDisplayModel.Net:
      return deal.totalRoomsPrice;
    default:
      throw new Error(`Unknown RateDisplayModel ${rateDisplayModel}`);
  }
}

export const notEmpty = <T>(value: T | undefined): value is T => value !== undefined;
