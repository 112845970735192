import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SEARCH_CHECKIN, SEARCH_CHECKOUT } from 'backend/localStorageKeys';
import { env } from 'environments/environment';
import { getInitialCheckin, getInitialCheckout } from 'utils/dateUtils';
import { isHotelRoute } from 'utils/uriUtils';
import useLocalStorage from 'utils/useLocalStorage';
import { validateDates, validateDateStrong } from 'utils/validation';

export interface StoredDate {
  checkin: string;
  checkout: string;
}

const useSearchFormCheckinCheckout = (checkinFromUrl?: string, checkoutFromUrl?: string) => {
  const { pathname } = useLocation();
  const [updateOnUrlChange, setUpdateOnUrlChange] = useState(true);

  const [checkinFromStorage, setCheckinFromStorage] = useLocalStorage<string>(
    SEARCH_CHECKIN,
    getInitialCheckin(checkinFromUrl),
  );
  const [checkoutFromStorage, setCheckoutFromStorage] = useLocalStorage<string>(
    SEARCH_CHECKOUT,
    getInitialCheckout(checkinFromStorage, checkoutFromUrl),
  );
  const calculateValue = useCallback(() => {
    if (checkinFromUrl) {
      const checkout = getInitialCheckout(checkinFromUrl, checkoutFromUrl);

      return { checkin: checkinFromUrl, checkout };
    }

    try {
      const offsetHours = isHotelRoute(pathname) ? env.times.offsetTimezoneHours : undefined;

      if (
        validateDateStrong(checkinFromStorage, offsetHours) &&
        validateDateStrong(checkoutFromStorage) &&
        validateDates(checkinFromStorage, checkoutFromStorage)
      ) {
        return { checkin: checkinFromStorage, checkout: checkoutFromStorage };
      }
      const checkin = getInitialCheckin();
      const checkout = getInitialCheckout(checkin);

      return { checkin, checkout };
    } catch (e) {
      const checkin = getInitialCheckin();
      const checkout = getInitialCheckout(checkin);

      return { checkin, checkout };
    }
  }, [checkinFromStorage, checkinFromUrl, checkoutFromStorage, checkoutFromUrl, pathname]);
  const [storedDate, setStoredDate] = useState<StoredDate>(calculateValue);

  const setCheckinCheckout = useCallback(
    (value: StoredDate) => {
      setUpdateOnUrlChange(false);
      setStoredDate(value);
      setCheckinFromStorage(value.checkin);
      setCheckoutFromStorage(value.checkout);
    },
    [setCheckinFromStorage, setCheckoutFromStorage],
  );

  return useMemo(() => {
    if (updateOnUrlChange && (checkinFromUrl !== storedDate.checkin || checkoutFromUrl !== storedDate.checkout)) {
      const value = calculateValue();

      setStoredDate(value);
      setCheckinFromStorage(value.checkin);
      setCheckoutFromStorage(value.checkout);

      return {
        ...value,
        setCheckinCheckout,
      };
    }

    return {
      checkin: storedDate.checkin,
      checkout: storedDate.checkout,
      setCheckinCheckout,
    };
  }, [
    calculateValue,
    checkinFromUrl,
    checkoutFromUrl,
    setCheckinCheckout,
    setCheckinFromStorage,
    setCheckoutFromStorage,
    storedDate.checkin,
    storedDate.checkout,
    updateOnUrlChange,
  ]);
};

export default useSearchFormCheckinCheckout;
